import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import "./ApplicantDetails.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import Footer from "../../components/Footer/Footer";
import {
  ArrowLeftOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  HistoryOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useSelector } from "react-redux";
import WindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import ModalForm from "../../components/ModalForm/ModalForm";
import { Link } from "react-router-dom";
import ApplicantItem from "../../components/ApplicantItem/ApplicantItem";

const ApplicantDetails = () => {
  const applicant = useSelector(
    (state) => state.job.applicantDetails.vacancy_details
  );

  const similar = useSelector(
    (state) => state.job.applicantDetails.similar_vacancies
  );

  let windowDimensions = WindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className='newsDetails'>
      {windowDimensions.width < 1100 ? <MobileHeader /> : <Header />}

      <Link to='/applicantsList' className='back'>
        <ArrowLeftOutlined style={{ color: "#ffcd00", marginRight: "8px" }} />
        Артка
      </Link>
      <div className='news_details_wrapper'>
        <div className='news_details_content'>
          <div className='content_wrapper'>
            <ImageGallery
              items={applicant?.photo?.map((it) => ({
                original: it?.photo,
                thumbnail: it?.photo,
              }))}
              showPlayButton={false}
              showFullscreenButton={false}
            />
            <div className='nd_title'>{applicant?.name}</div>
            <div className='applicantDetails_header'>
              <div className='nd_price'>{applicant?.salary} </div>
              <div className='applicant_deadline'>
                <HistoryOutlined className='icon' />
                Жарыяланды: {applicant?.posted_date}
              </div>
            </div>

            <div className='applicant_address'>
              {" "}
              <EnvironmentOutlined className='icon' />
              {applicant?.address}
            </div>

            <div
              className='ns_desription'
              dangerouslySetInnerHTML={{ __html: applicant?.description }}
            ></div>
            <div className='ns_form'>
              <div className='post_detail_recall'>
                {" "}
                <PhoneOutlined style={{ color: "white" }} />
                &nbsp;
                <Link to={`tel:+996${applicant?.phone}`}>
                  0{applicant?.phone}
                </Link>
              </div>
            </div>
          </div>

          <div className='news_details_vertical_add'>
            <div className='applicant_add_wrapper'>
              <div className='add_item'>
                <div className='add_img_block'>
                  <img src='/assets/add1.svg' alt='' className='add_img' />
                </div>
              </div>
              <div className='add_item'>
                <div className='add_img_block'>
                  <img src='/assets/add2.svg' alt='' className='add_img' />
                </div>
              </div>
              <div className='add_item'>
                <div className='add_img_block'>
                  <img src='/assets/add1.svg' alt='' className='add_img' />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='news_details_horizontal_add'>
          <img src='/assets/balance.svg' alt='' className='horizontal_img' />
        </div> */}

        <div className='bottom_block'>
          <div className='new_details_similar_applicants'>
            <h3>Башка ишчилер</h3>
            {similar?.map((it) => (
              <div style={{ margin: "15px" }}>
                <ApplicantItem applicant={it} />
              </div>
            ))}
          </div>
          <div className='news_details_vertical_add2'>
            <div className='add_item'>
              <div className='add_img_block'>
                <img src='/assets/add3.svg' alt='' className='add_img' />
              </div>
            </div>
            <div className='add_item'>
              <div className='add_img_block'>
                <img src='/assets/add4.svg' alt='' className='add_img' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalForm
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        id={applicant?.id}
      />
      <Footer />
    </div>
  );
};

export default ApplicantDetails;
