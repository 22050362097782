import React from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./CategoryList.module.scss";
import { Tag } from "antd";
import { getFilteredJobList } from "../../redux/JobSlice";

const CategoryList = () => {
  const jobList = useSelector((state) => state.job.categories);
  const dispatch = useDispatch();
  const colors = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  return (
    <div className={s.jobList}>
      <h3 className={s.h3}>Популярные запросы</h3>
      <div className={s.content}>
        <div className={s.inner}>
          {Array.isArray(jobList) &&
            jobList?.map((it, index) => (
              <Tag
                key={index}
                className={s.job}
                color={getRandomColor()}
                onClick={() => {
                  dispatch(getFilteredJobList({ profession: it?.id }));
                }}
              >
                {it?.name}
              </Tag>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryList;
