import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import NewsDetails from "./pages/NewsDetails/NewsDetails";
import ApplicantsList from "./pages/ApplicantsList/ApplicantsList";
import VacancyList from "./pages/VacancyList/VacancyList";
import VacancyForm from "./pages/VacancyForm/VacancyForm";
import ApplicantForm from "./pages/ApplicantForm/ApplicantForm";
import ApplicantDetails from "./pages/ApplicantDetails/ApplicantDetails";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/job/:id' element={<NewsDetails />} />
        <Route path='/applicant/:id' element={<ApplicantDetails />} />
        <Route path='/applicants' element={<ApplicantsList />} />
        <Route path='/vacancyList' element={<VacancyList />} />
        <Route path='/applicantsList' element={<ApplicantsList />} />
        <Route path='/vacancyForm' element={<VacancyForm />} />
        <Route path='/applicantForm' element={<ApplicantForm />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
