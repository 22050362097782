import React from "react";
import "./Footer.css";
import { BuildOutlined } from "@ant-design/icons";

const Footer = () => {
  return (
    <div className='footer'>
      <div className='top_footer'>
        <div className='logo'>
          <BuildOutlined
            style={{ fontSize: "20px", marginRight: "8px" }}
            className='icon'
          />
          SIMSAR
        </div>
        <div className='footer_content'>
          <div className='footer_item'>
            <div className='footer_title'>Дареги:</div> &nbsp;
            <div className='footer_value'>г.Бишкек</div>
          </div>
          <div className='footer_item'>
            <div className='footer_title'>Телефон:</div> &nbsp;
            <div className='footer_value'>+996 500 000 000</div>
          </div>
        </div>
      </div>
      <div className='bottom_footer'>Все права защищены</div>
    </div>
  );
};

export default Footer;
