import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Filter from "../../components/Filter/Filter";
import JobList from "../../components/JobList/JobList";
import Footer from "../../components/Footer/Footer";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useDispatch } from "react-redux";
import {
  getApplicantsList,
  getCategories,
  getJobList,
  getLocation,
} from "../../redux/JobSlice";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import CategoryList from "../../components/CategoryList/CategoryList";

const Home = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  useEffect(() => {
    dispatch(getJobList());
    dispatch(getLocation());
    dispatch(getCategories());
    dispatch(getApplicantsList());
  }, []);

  return (
    <div className='home'>
      {width < 1000 ? <MobileHeader /> : <Header />}
      {width > 1200 ? (
        <div>
          <Filter />
          <CategoryList />
        </div>
      ) : (
        <div>
          <CategoryList />
          <Filter />
        </div>
      )}

      <JobList />
      <Footer />
    </div>
  );
};

export default Home;
