import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Button, Col, Form, Input, Row, Select, Upload, message } from "antd";
import "./ApplicantForm.css";
import { useDispatch, useSelector } from "react-redux";
import { createJobSeekerApplication } from "../../redux/JobSlice";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { UploadOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

const props = {
  name: "file",
  action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
  headers: {
    authorization: "authorization-text",
  },
  onChange(info) {
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  progress: {
    strokeColor: {
      "0%": "#108ee9",
      "100%": "#87d068",
    },
    strokeWidth: 3,
    format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
  },
};

const ApplicantForm = () => {
  const [form] = Form.useForm();
  const jobCategories = useSelector((state) => state.job.categories);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [region, setRegion] = useState();
  const [districts, setDiscticts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();
  const { width } = useWindowDimensions();
  const [file, setFile] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: "success",
      content: "Вы создали объявление!",
    });
  };

  const location = useSelector((state) => state.job.location);

  const selectDistricts = (regionId) => {
    const res = location.find((it) => it.id === regionId)?.sub_locations;
    setDiscticts(res);
  };

  const addVacancy = async (values) => {
    const data = new FormData();
    // values.validateFields();

    data.append("name", values.name);
    data.append("salary", values.salary);
    data.append("short_description", values.short_description);
    data.append("description", values.description);
    data.append("address", values.address);
    data.append("phone", values.phone);
    data.append("profession", values.profession);
    data.append("location", values.region);
    data.append("sub_location", selectedDistrict);

    if (file.fileList && file.fileList.length > 0) {
      file.fileList.forEach((fileItem) => {
        data.append("photo", fileItem.originFileObj);
      });
    }

    const res = await dispatch(createJobSeekerApplication(data));

    if (res.type === "job/createJobSeekerApplication/fulfilled") {
      success();
      navigate("/applicantsList");
    }
  };

  return (
    <div>
      {contextHolder}
      {width < 1000 ? <MobileHeader /> : <Header />}
      <div className='wrapper'>
        <div className='form'>
          <h2>Ишчини каттоо формасы</h2>
          <Form
            layout='vertical'
            form={form}
            onFinish={addVacancy}
            encType='multipart/form-data'
          >
            <Form.Item
              label='Аты жөнү'
              name='name'
              rules={[
                {
                  required: true,
                  message: "Аты жөнүңүздү жазыңыз",
                },
              ]}
            >
              <Input
                className='formInput'
                placeholder='Аты жөнүңүздү жазыңыз'
              />
            </Form.Item>
            <Form.Item
              label='Кызматыныз тууралуу кыска маалымат'
              name='short_description'
              rules={[
                {
                  required: true,
                  message: "Кызматыныз тууралуу кыска маалымат жазыңыз",
                },
              ]}
            >
              <Input
                className='formInput'
                placeholder='Кызматыныз тууралуу кыска маалымат жазыңыз'
              />
            </Form.Item>
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item
                  label='Кызмат акы'
                  name='salary'
                  rules={[
                    {
                      required: true,
                      message: "Кызмат акыңызды жазыңыз",
                    },
                  ]}
                >
                  <Input
                    className='formInput'
                    placeholder='Кызмат акыңызды жазыңыз'
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label='Кызматыңыз/тажрыйбаңыз тууралуу кененирээк'
              name='description'
              rules={[
                {
                  required: true,
                  message: "Кызматыңыз/тажрыйбаңыз тууралуу кененирээк жазыңыз",
                },
              ]}
            >
              <TextArea
                className='formInput'
                placeholder='Кызматыңыз/тажрыйбаңыз тууралуу кененирээк жазыңыз'
                style={{ minHeight: "250px" }}
              />
            </Form.Item>
            <Row gutter={8}>
              <Col span={width > 725 ? 4 : 10}>
                <Form.Item name={"code"} label='Код'>
                  <div className='code'>
                    <img src='/kyrgyzstan.png' className='flag' />
                    +996
                  </div>
                </Form.Item>
              </Col>
              <Col span={width > 725 ? 8 : 14}>
                <Form.Item
                  name='phone'
                  label='Телефон'
                  rules={[
                    {
                      required: true,
                      message: "Номериңизди 500123456 үлгүсүндө жазыңыз",
                    },

                    {
                      len: 9,
                      message: "Телефон номер 9 цифрадан болуш керек",
                    },
                    {
                      validator: (_, value) => {
                        if (!/^\d+$/.test(value)) {
                          return Promise.reject(
                            new Error("Телефон номери цифрадан болуш керек")
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input className='formInput' placeholder='үлгү: 500123456' />
                </Form.Item>
              </Col>
              <Col span={width > 725 ? 12 : 24}>
                <Form.Item
                  label='Кесиб'
                  name='profession'
                  rules={[
                    {
                      required: true,
                      message: "Кесипти тандаңыз",
                    },
                  ]}
                >
                  <Select className='formInput' placeholder='Кесипти тандаңыз'>
                    {jobCategories.map((it) => (
                      <Option value={it.id}>{it.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label='Область'
                  name='region'
                  rules={[
                    {
                      required: true,
                      message: "Область тандаңыз",
                    },
                  ]}
                >
                  <Select
                    className='formInput'
                    placeholder='Область тандаңыз'
                    onSelect={(value) => {
                      setRegion(value);
                      selectDistricts(value);
                      setSelectedDistrict(undefined);
                    }}
                  >
                    {location.map((it) => (
                      <Option value={it.id}>{it.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Район'
                  name='district'
                  rules={[
                    {
                      required: true,
                      message: "Район тандаңыз",
                    },
                  ]}
                >
                  <Select
                    className='formInput'
                    placeholder='Район тандаңыз'
                    value={selectedDistrict}
                    onSelect={(value) => setSelectedDistrict(value)}
                    options={districts?.map((it) => ({
                      value: it.id,
                      label: it.name,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label='Дареги' name='address'>
              <Input className='formInput' placeholder='Дареги жазыңыз' />
            </Form.Item>
            <Col span={width > 800 ? 12 : 24}>
              <Form.Item
                name='accompanying_documents'
                label='Жумуштарыңыздын сүрөттөрү болсо, кощуңуз'
              >
                <Upload
                  name='accompanying_documents'
                  multiple
                  {...props}
                  onChange={(e) => setFile(e)}
                >
                  <Button icon={<UploadOutlined />} className={"btn"}>
                    Жүктөө
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Row className='btn_row'>
              <Button htmlType='submit' className='form_btn'>
                Жарыяны берүү
              </Button>
            </Row>
          </Form>
        </div>
        <div className='add'>
          <div className='job_add_wrapper'>
            <div className='add_item'>
              <div className='add_img_block'>
                <img src='/assets/add1.svg' alt='' className='add_img' />
              </div>
            </div>
            <div className='add_item'>
              <div className='add_img_block'>
                <img src='/assets/add2.svg' alt='' className='add_img' />
              </div>
            </div>
            <div className='add_item'>
              <div className='add_img_block'>
                <img src='/assets/add1.svg' alt='' className='add_img' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ApplicantForm;
