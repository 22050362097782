import React, { useEffect, useState } from "react";
import "./JobItem.css";
import {
  EnvironmentOutlined,
  HistoryOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getJobDetails } from "../../redux/JobSlice";

import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { Badge, Tag } from "antd";

const JobItem = ({ job }) => {
  const dispatch = useDispatch();
  const professions = useSelector((state) => state.job.categories);

  return (
    // <Badge.Ribbon
    //   text={professions.find((it) => it.id === job.id)?.name}
    //   color={"#ffde21"}
    // >
    <div className='job_item'>
      <Tag className='tag' color={"orange"}>
        {professions.find((it) => it.id === job.profession)?.name}
      </Tag>
      <Link
        to={`/job/${job?.id}`}
        onClick={() => dispatch(getJobDetails(job?.id))}
      >
        <div className='job_title'>{job?.title}</div>
        <div className='job_salary'>от {job?.salary} cом</div>
        <div className='job_item_date'></div>
        {job?.short_description ? (
          <div className='job_description'> {job?.short_description}</div>
        ) : null}
        <div className='job_item_location'>
          <EnvironmentOutlined className='icon' />
          {job?.address}
        </div>
      </Link>

      <div className='job_btns'>
        <div className='job_deadline'>
          <HistoryOutlined className='icon' />
          &nbsp;Жарыяланды:&nbsp;{job?.posted_date}
        </div>
        <div className='btns_wrapper'>
          <Link
            to={`tel:+996${job?.phone}`}
            className='job_red_btn'
            style={{ marginRight: "5px" }}
          >
            <PhoneOutlined
              style={{
                color: "whitesmoke",
                marginRight: "5px",
              }}
            />
            0{job?.phone}
          </Link>
          <Link
            to={`/job/${job.id}`}
            className='job_red_btn'
            onClick={() => dispatch(getJobDetails(job?.id))}
          >
            Кененирээк
          </Link>
        </div>
      </div>

      <ScrollToTop />
    </div>
    // </Badge.Ribbon>
  );
};

export default JobItem;
