import React, { useState } from "react";
import "./MobileHeader.css";
import { Button, Dropdown, Modal, Segmented } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  BarsOutlined,
  BuildOutlined,
  DownOutlined,
  FileAddOutlined,
  FileProtectOutlined,
  HomeOutlined,
  PhoneOutlined,
  TagOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import "./MobileHeader.css";

const MobileHeader = ({ scrollToComponent }) => {
  const [isModalOpen, setIsModalOpen] = useState();
  let navigate = useNavigate();
  const location = useLocation();

  const onChange = (value) => {
    console.log("value", value);

    if (value === "vacancies") {
      navigate("/vacancyList");
    }
    if (value === "jobseakers") {
      navigate("/applicantsList");
    }
  };

  const items = [
    {
      key: "1",
      label: <Link to='/vacancyList'>Все вакансии</Link>,
    },
    {
      key: "2",
      label: <Link to='/vacancyForm'>Создать вакансию</Link>,
    },
  ];

  const items2 = [
    {
      key: "3",
      label: <Link to='/applicantsList'>Все соискатели</Link>,
    },
    {
      key: "4",
      label: <Link to='/applicantForm'>Создать объявление</Link>,
    },
  ];

  return (
    <div className='mobile_header'>
      <div className='mobile_header_inner'>
        <div className='top_header'>
          <div className='logo_block'>
            <Link to='/' className='logo' style={{ textDecoration: "none" }}>
              <BuildOutlined className='icon' />
              SIMSAR
            </Link>
          </div>
          <Link to='/' className='mobile_menu'>
            ГЛАВНАЯ
          </Link>
          <div className='mobile_menu' onClick={() => setIsModalOpen(true)}>
            МEНЮ
          </div>
        </div>
      </div>
      <div className='segemented'>
        <Dropdown menu={{ items }} placement='bottomLeft' arrow>
          <Button className='btn'>
            Вакансии <DownOutlined />
          </Button>
        </Dropdown>

        <Dropdown menu={{ items: items2 }} placement='bottomLeft' arrow>
          <Button className='btn'>
            Соискатели <DownOutlined />
          </Button>
        </Dropdown>
      </div>

      {/* <div className='inline_menu'>
        <Link to='/vacancyList' className='inline_menu_item'>
          Жумуштар
        </Link>
        <Link to='/applicantsList' className='inline_menu_item'>
          Ишчилер
        </Link>
        <Link to='/applicantForm' className='inline_menu_item'>
          Жумуш издөө
        </Link>
        <Link to='/vacancyForm' className='inline_menu_item'>
          Жумуш берүү
        </Link>
      </div> */}

      <Modal
        width={400}
        height={280}
        onCancel={() => setIsModalOpen(false)}
        style={{ borderRadius: "10px", width: "90%", margin: "0 auto" }}
        cancelButtonProps={{ style: { display: "none", color: "whitesmoke" } }}
        okButtonProps={{ style: { display: "none" } }}
        className='mobile_menu_modal'
        open={isModalOpen}
      >
        <form className='mobile_header' onSubmit={(e) => e.preventDefault()}>
          <Link to='/vacancyList' className='mobile_menu_item'>
            <HomeOutlined className='icon' />
            ЖУМУШТАР
          </Link>
          <Link to='/applicantsList' className='mobile_menu_item'>
            <TeamOutlined className='icon' />
            ИШЧИЛЕР
          </Link>
          <Link to='/vacancyForm' className='mobile_menu_item'>
            <FileProtectOutlined className='icon' />
            ЖУМУШ ИЗДӨӨ
          </Link>
          <Link to='/applicantForm' className='mobile_menu_item'>
            <FileAddOutlined className='icon' />
            ЖУМУШ БЕРҮҮ
          </Link>
        </form>
      </Modal>
    </div>
  );
};

export default MobileHeader;
