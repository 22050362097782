import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { publicRequest, userRequest } from "../utils";

const initialState = {
  jobList: [],
  job: {},
  user: {},
  loading: false,
  categories: [],
  locations: [],
  post: {},
  location: [],
  applicantsList: [],
  applicantDetails: {},
};

export const getJobList = createAsyncThunk("job/getJobList", async () => {
  try {
    const { data } = await publicRequest.get("/main/vacancies/");
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getLocation = createAsyncThunk("job/getLocations", async () => {
  try {
    const { data } = await publicRequest.get("/main/locations/");
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getCategories = createAsyncThunk("job/getCategories", async () => {
  try {
    const { data } = await publicRequest.get("/main/categories/");
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getApplicantsList = createAsyncThunk(
  "job/getApplicantsList",
  async () => {
    try {
      const { data } = await userRequest.get(`main/jobseeker/`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getJobDetails = createAsyncThunk(
  "job/getJobDetails",
  async (id) => {
    try {
      const { data } = await publicRequest.get(`/main/vacancies/${id}/`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getApplicantDetails = createAsyncThunk(
  "job/getApplicantDetails",
  async (id) => {
    try {
      const { data } = await publicRequest.get(`main/jobseeker/${id}/`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const addPost = createAsyncThunk("job/addPost", async (post) => {
  try {
    const { data } = await userRequest.post(`/main/vacancies/`, post);
    console.log("otp", data);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const getFilteredJobList = createAsyncThunk(
  "job/getFilteredJobList",
  async (info) => {
    console.log("phone", info);
    try {
      const { data } = await publicRequest.post(`/main/filter/`, info);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const createVacancy = createAsyncThunk(
  "job/createVacancy",
  async (info) => {
    console.log("phone", info);
    try {
      const { data } = await publicRequest.post(`main/vacancies/`, info);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const createJobSeekerApplication = createAsyncThunk(
  "job/createJobSeekerApplication",
  async (info) => {
    try {
      const { data } = await publicRequest.post(`main/jobseeker/`, info);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = {};
      state.isLoading = false;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getJobList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getJobList.fulfilled, (state, action) => {
        state.jobList = action.payload;
        state.loading = false;
      })
      .addCase(getJobList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getLocation.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocation.fulfilled, (state, action) => {
        state.location = action.payload;
        state.loading = false;
      })
      .addCase(getLocation.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getFilteredJobList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFilteredJobList.fulfilled, (state, action) => {
        state.jobList = action.payload;
        state.loading = false;
      })
      .addCase(getFilteredJobList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(addPost.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPost.fulfilled, (state, action) => {
        state.post = action.payload;

        state.loading = false;
      })
      .addCase(addPost.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getJobDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getJobDetails.fulfilled, (state, action) => {
        state.job = action.payload;
        state.loading = false;
      })
      .addCase(getJobDetails.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.loading = false;
      })
      .addCase(getCategories.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getApplicantsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplicantsList.fulfilled, (state, action) => {
        state.applicantsList = action.payload;
        state.loading = false;
      })
      .addCase(getApplicantsList.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getApplicantDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getApplicantDetails.fulfilled, (state, action) => {
        state.applicantDetails = action.payload;
        state.loading = false;
      })
      .addCase(getApplicantDetails.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { logout } = jobSlice.actions;
export default jobSlice.reducer;
