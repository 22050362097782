import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import "./ApplicantsList.css";
import ApplicantItem from "../../components/ApplicantItem/ApplicantItem";
import { getApplicantsList } from "../../redux/JobSlice";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimentions from "../../components/WindowDimensions/WindowDimensions";

const VacancyList = () => {
  const applicantsList = useSelector((state) => state.job.applicantsList);
  const dispatch = useDispatch();
  const { width } = useWindowDimentions();

  useEffect(() => {
    dispatch(getApplicantsList());
  }, [dispatch]);

  return (
    <div className='vacancyList'>
      {width < 1000 ? <MobileHeader /> : <Header />}

      <h2 className='h2'>Ишчилердин тизмеси</h2>
      <div className='vacancyList_content'>
        {applicantsList?.map((it) => (
          <ApplicantItem applicant={it} key={it.id} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default VacancyList;
