import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Button, Col, Form, Input, Row, Select, message } from "antd";
import "./VacancyForm.css";
import { useDispatch, useSelector } from "react-redux";
import { createVacancy } from "../../redux/JobSlice";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";

const { TextArea } = Input;
const { Option } = Select;

const VacancyForm = () => {
  const [form] = Form.useForm();
  const jobCategories = useSelector((state) => state.job.categories);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [region, setRegion] = useState();
  const [districts, setDiscticts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();
  const { width } = useWindowDimensions();

  const location = useSelector((state) => state.job.location);
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: "success",
      content: "Вы создали объявление!",
    });
  };

  const selectDistricts = (regionId) => {
    const res = location.find((it) => it.id === regionId)?.sub_locations;
    setDiscticts(res);
  };

  const addVacancy = async (values) => {
    const data = {
      title: values.title,
      salary: values.salary,
      short_description: values.short_description,
      description: values.description,
      address: values.address,
      deadline: values.deadline,
      phone: values.phone,
      profession: values.profession,
      location: values.region,
      sub_location: selectedDistrict,
    };

    const res = await dispatch(createVacancy(data));

    if (res.type === "job/createVacancy/fulfilled") {
      success();
      navigate("/vacancyList");
    }
  };

  return (
    <div>
      {contextHolder}
      {width < 1000 ? <MobileHeader /> : <Header />}
      <div className='wrapper'>
        <div className='form'>
          <h2>Жаңы жумуш ордун кошуу</h2>
          <Form layout='vertical' form={form} onFinish={addVacancy}>
            <Form.Item
              label='Жумуш темасын жазыңыз'
              name='title'
              rules={[
                {
                  required: true,
                  message: "Жумуш темасын жазыңыз",
                },
              ]}
            >
              <Input
                className='formInput'
                placeholder='Жумуш темасын жазыңыз'
              />
            </Form.Item>
            <Form.Item
              label='Кыскача жумуш тууралуу маалымат'
              name='short_description'
              rules={[
                {
                  required: true,
                  message: "Кыскача жумуш тууралуу маалымат",
                },
              ]}
            >
              <Input
                className='formInput'
                placeholder='Кыскача жумуш тууралуу маалымат жазыңыз'
              />
            </Form.Item>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item label='Айлыгы (маяна)' name='salary'>
                  <Input className='formInput' placeholder='Айлыгы (маяна)' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label='Акыркы мөөнөт' name='deadline'>
                  <Input
                    className='formInput'
                    placeholder='Акыркы мөөнөтү жазыңыз'
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label='Жумуш жонундо кененирээк'
              name='description'
              rules={[
                {
                  required: true,
                  message: "Жумуш жонундо кененирээк жазыңыз",
                },
              ]}
            >
              <TextArea
                className='formInput'
                placeholder='Жумуш жонундо кененирээк жазыңыз'
                style={{ minHeight: "250px" }}
              />
            </Form.Item>

            <Row gutter={8}>
              <Col span={width > 725 ? 4 : 10}>
                <Form.Item name={"code"} label='Код'>
                  <div className='code'>
                    <img src='/kyrgyzstan.png' className='flag' />
                    +996
                  </div>
                </Form.Item>
              </Col>
              <Col span={width > 725 ? 8 : 14}>
                <Form.Item
                  name='phone'
                  label='Телефон'
                  rules={[
                    {
                      required: true,
                      message: "Номериңизди 500123456 үлгүсүндө жазыңыз.",
                    },

                    {
                      len: 9,
                      message: "Телефон номер 9 цифрадан болуш керек.",
                    },
                    {
                      validator: (_, value) => {
                        if (!/^\d+$/.test(value)) {
                          return Promise.reject(
                            new Error("Телефон номери цифрадан болуш керек")
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input className='formInput' placeholder='үлгү: 500123456' />
                </Form.Item>
              </Col>
              <Col span={width > 725 ? 12 : 24}>
                <Form.Item
                  label='Кесиб'
                  name='profession'
                  rules={[
                    {
                      required: true,
                      message: "Кесипти тандаңыз",
                    },
                  ]}
                >
                  <Select className='formInput' placeholder='Кесипти тандаңыз'>
                    {jobCategories.map((it) => (
                      <Option value={it.id}>{it.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  label='Область'
                  name='region'
                  rules={[
                    {
                      required: true,
                      message: "Область тандаңыз",
                    },
                  ]}
                >
                  <Select
                    className='formInput'
                    placeholder='Область тандаңыз'
                    onSelect={(value) => {
                      setRegion(value);
                      selectDistricts(value);
                      setSelectedDistrict(undefined);
                    }}
                  >
                    {location.map((it) => (
                      <Option value={it.id}>{it.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label='Район'
                  name='district'
                  rules={[
                    {
                      required: true,
                      message: "Район тандаңыз",
                    },
                  ]}
                >
                  <Select
                    className='formInput'
                    placeholder='Район тандаңыз'
                    value={selectedDistrict}
                    onSelect={(value) => setSelectedDistrict(value)}
                    options={districts?.map((it) => ({
                      value: it.id,
                      label: it.name,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label='Дареги'
              name='address'
              rules={[
                {
                  required: true,
                  message: "Дареги тандаңыз",
                },
              ]}
            >
              <Input className='formInput' placeholder='Дареги жазыңыз' />
            </Form.Item>
            <Row className='btn_row'>
              <Button htmlType='submit' className='form_btn'>
                Жарыяны берүү
              </Button>
            </Row>
          </Form>
        </div>
        <div className='add'>
          <div className='job_add_wrapper'>
            <div className='add_item'>
              <div className='add_img_block'>
                <img src='/assets/add1.svg' alt='' className='add_img' />
              </div>
            </div>
            <div className='add_item'>
              <div className='add_img_block'>
                <img src='/assets/add2.svg' alt='' className='add_img' />
              </div>
            </div>
            <div className='add_item'>
              <div className='add_img_block'>
                <img src='/assets/add1.svg' alt='' className='add_img' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default VacancyForm;
