import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import JobItem from "../../components/JobItem/JobItem";
import "./VacancyList.css";
import { getJobList } from "../../redux/JobSlice";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";

const VacancyList = () => {
  const dispatch = useDispatch();
  const jobList = useSelector((state) => state.job.jobList);
  const { width } = useWindowDimensions();

  useEffect(() => {
    dispatch(getJobList());
  }, [dispatch]);

  return (
    <div className='vacancyList'>
      {width < 1000 ? <MobileHeader /> : <Header />}

      <h2 className='h2'>Жумуштардын тизмеси</h2>
      <div className='vacancyList_content'>
        {jobList?.map((it) => (
          <JobItem job={it} key={it.id} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default VacancyList;
