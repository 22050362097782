import { Form, Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../redux/JobSlice";
import { BuildOutlined } from "@ant-design/icons";
import "./ModalForm.css";
import { Link } from "react-router-dom";

const ModalForm = ({ isModalOpen, setIsModalOpen, id }) => {
  const [form] = Form.useForm();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [file, setFile] = useState();
  const [comment, setComment] = useState();
  const dispatch = useDispatch();
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  let is_authorized = useSelector((state) => state?.job.user?.access);

  const replyForJobPost = (e) => {
    e.preventDefault();
    form.validateFields();
    const data = new FormData();

    if (name) {
      data.append("first_name", name);
    }
    if (phone) {
      data.append("phone", phone);
    }
    if (file) {
      data.append("resume", file);
    }
    if (comment) {
      data.append("comments", comment);
    }
    data.append("vacancy", id);
    // dispatch(applyForJob({info: data, is_authorized}))

    if (name && name.length > 1 && phone && phone.length > 1) {
      setIsModalOpen(false);
      setIsSecondModalOpen(true);
      setComment(undefined);
      setName(undefined);
      setFile(undefined);
      setPhone(undefined);
    }
  };

  return (
    <div>
      <Modal
        className='modal'
        width={400}
        height={100}
        onCancel={() => setIsModalOpen(false)}
        style={{
          borderRadius: "10px",
          width: "90%",
          margin: "0 auto",
          padding: "50px 20px",
        }}
        cancelButtonProps={{ style: { display: "none", color: "whitesmoke" } }}
        okButtonProps={{ style: { display: "none" } }}
        open={isModalOpen}
      >
        <Form
          form={form}
          className='apply_modal'
          onSubmit={(e) => e.preventDefault()}
        >
          <Link to='/' className='logo' style={{ textDecoration: "none" }}>
            {" "}
            <BuildOutlined style={{ fontSize: "24px" }} /> SIMSAR
          </Link>
          <div style={{ margin: "20px auto" }}>
            <Form.Item
              name='name'
              rules={[
                {
                  required: true,
                  message: "Введите ФИО",
                },
              ]}
            >
              <Input
                className='modal_input'
                placeholder='Ваше ФИО'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name='phone'
              rules={[
                {
                  required: true,
                  message: "Введите номер телефона",
                },
              ]}
            >
              <Input
                className='modal_input'
                placeholder='Ваш номер телефона'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Item>
            <Form.Item name='file'>
              <Input
                className='modal_input'
                type='file'
                value={file}
                placeholder='Прикрепите файл'
                onChange={(e) => setFile(e.target.files[0])}
              />
            </Form.Item>

            <TextArea
              className='modal_input'
              placeholder='Добавьте комментарий'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            ></TextArea>
            <div className='btn_wrapper'>
              <button
                type='submit'
                className='modal_btn'
                onClick={(e) => replyForJobPost(e)}
              >
                Откликнуться
              </button>
            </div>
          </div>
        </Form>
      </Modal>
      <Modal
        width={400}
        height={280}
        onCancel={() => setIsSecondModalOpen(false)}
        style={{
          borderRadius: "10px",
          width: "90%",
          margin: "0 auto",
          padding: "50px 20px",
        }}
        cancelButtonProps={{ style: { display: "none", color: "whitesmoke" } }}
        okButtonProps={{ style: { display: "none" } }}
        open={isSecondModalOpen}
      >
        <div>
          <div
            className='logo'
            style={{ textDecoration: "none", textAlign: "center" }}
          >
            {" "}
            <BuildOutlined style={{ fontSize: "24px" }} /> SIMSAR
          </div>
          <div
            style={{
              textAlign: "center",
              margin: "15px auto",
              fontWeight: "600",
            }}
          >
            Вы откликнулись на данную вакансию!
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalForm;
