import React, { useEffect, useState } from "react";
import "./ApplicantItem.css";
import {
  EnvironmentOutlined,
  HistoryOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { getApplicantDetails } from "../../redux/JobSlice";
import { Tag } from "antd";

const ApplicantItem = ({ applicant }) => {
  const dispatch = useDispatch();
  const professions = useSelector((state) => state.job.categories);

  return (
    <div className='applicant_item'>
      <Link
        to={`/applicant/${applicant?.id}`}
        onClick={() => dispatch(getApplicantDetails(applicant?.id))}
      >
        <Tag className='tag2' color={"orange"}>
          {professions.find((it) => it.id === applicant.profession)?.name}
        </Tag>
        <div className='applicant_item_content'>
          <div className='applicant_item_img'>
            {applicant.photo[0]?.photo ? (
              <img src={applicant.photo[0]?.photo} className='item_img' />
            ) : (
              <img src={"/assets/no_Image.png"} className='item_img' />
            )}
          </div>
          <div className='text'>
            <div className='applicant_title'>{applicant?.name}</div>
            <div className='applicant_salary'> {applicant?.salary} </div>
            <div className='applicant_item_date'></div>
            {applicant?.short_description ? (
              <div className='applicant_description'>
                {" "}
                {applicant?.short_description}
              </div>
            ) : null}
            <div className='applicant_item_location'>
              <EnvironmentOutlined className='icon' />
              {applicant?.address}
            </div>
          </div>
        </div>
      </Link>
      <div className='applicant_btns'>
        <div className='applicant_deadline'>
          <HistoryOutlined className='icon' />
          Жарыяланды:&nbsp;{applicant?.posted_date}
        </div>
        <div className='btns_wrapper'>
          <Link
            to={`tel:+996${applicant?.phone}`}
            className='applicant_red_btn'
          >
            <PhoneOutlined
              style={{
                color: "whitesmoke",
                marginRight: "8px",
              }}
            />
            0{applicant?.phone}
          </Link>
          <Link
            to={`/applicant/${applicant.id}`}
            className='applicant_red_btn'
            onClick={() => dispatch(getApplicantDetails(applicant?.id))}
          >
            Кененирээк
          </Link>
        </div>
      </div>

      <ScrollToTop />
    </div>
  );
};

export default ApplicantItem;
