import React, { useState } from "react";
import "./Filter.css";
import {
  AppstoreOutlined,
  BarsOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  NodeIndexOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Col, Input, Row, Segmented, Select } from "antd";
import { Option } from "antd/es/mentions";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredJobList } from "../../redux/JobSlice";
import { Link, useNavigate } from "react-router-dom";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";

const Filter = () => {
  const [region, setRegion] = useState();
  const [districts, setDiscticts] = useState();
  const { width } = useWindowDimensions();
  const location = useSelector((state) => state.job.location);
  const categories = useSelector((state) => state.job.categories);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState();
  const [jobCategory, setJobCategory] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();

  const selectLocation = (value) => {
    setRegion(value);
    let res = location.find((it) => it.id === value).sub_locations;
    setDiscticts(res);
  };

  const filterJobList = (e) => {
    e.preventDefault();
    const res = {};
    if (searchParams) {
      res["search"] = searchParams.trim();
    }

    if (region) {
      res["location"] = region;
    }
    if (selectedDistrict) {
      res["sub_location"] = selectedDistrict;
    }
    if (jobCategory) {
      res["profession"] = jobCategory;
    }
    dispatch(getFilteredJobList(res));
  };

  return (
    <div className='filter'>
      {width > 1250 ? (
        <div className='filter_title'>ЖУМУШ ИЗДӨӨ ЖАНА ЖУМУШ БЕРҮҮ САЙТЫ</div>
      ) : null}

      {width > 1000 ? <div></div> : null}
      <div className='filter_block'>
        <Row gutter={0}>
          <Col span={12}>
            <div className='filter_item'>
              {width > 1250 ? (
                <EnvironmentOutlined
                  style={{
                    color: "#ffcd00",
                    marginLeft: "20px",
                    fontSize: "16px",
                  }}
                />
              ) : null}
              <Select
                className='filter_select'
                placeholder='Область'
                bordered={false}
                onSelect={(value) => selectLocation(value)}
                allowClear={true}
                onClear={() => setRegion(undefined)}
              >
                {location?.map((it) => (
                  <Option value={it?.id}>{it?.name}</Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={12}>
            <div className='filter_item'>
              {width > 1250 ? (
                <NodeIndexOutlined
                  style={{
                    color: "#ffcd00",
                    marginLeft: "20px",
                    fontSize: "16px",
                  }}
                />
              ) : null}

              <Select
                className='filter_select'
                placeholder='Район'
                bordered={false}
                allowClear={true}
                onSelect={(value) => setSelectedDistrict(value)}
                onClear={() => setSelectedDistrict(undefined)}
              >
                {districts?.map((it) => (
                  <Option value={it?.id}>{it?.name}</Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <div className='filter_item'>
              <UserAddOutlined
                style={{
                  color: "#ffcd00",
                  marginLeft: "20px",
                  fontSize: "16px",
                }}
              />
              <Input
                className='filter_input'
                placeholder='Издөө'
                bordered={false}
                allowClear={true}
                onChange={(e) => setSearchParams(e.target.value)}
                value={searchParams}
                onClear={() => setSearchParams(undefined)}
              />
            </div>
          </Col>
          <Col span={4}>
            <button
              className='filter_btn'
              onClick={(e) => {
                filterJobList(e);
                navigate("/vacancyList");
              }}
            >
              Издөө
            </button>
          </Col>
        </Row>
      </div>
      {/* <div className='filter_description'>
        The Social Impact team empowers nonprofits and the greater social sector
        to drive positive and lasting contributions to the world with GitHub
        products, our brand, and our employees.
      </div> */}
    </div>
  );
};

export default Filter;
