import React from "react";
import "./Header.css";
import { Link, useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  BarsOutlined,
  BuildOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";

import { Button, Dropdown, Segmented } from "antd";

const Header = () => {
  // const name = useSelector((state) => state?.job?.userInfo);
  // const token = useSelector((state) => state?.job?.user?.access);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const logOut = () => {
  //   dispatch(logout());
  //   window.localStorage.removeItem("token");
  // };

  const items = [
    {
      key: "1",
      label: <Link to='/vacancyList'>Все вакансии</Link>,
    },
    {
      key: "2",
      label: <Link to='/vacancyForm'>Создать вакансию</Link>,
    },
  ];

  const items2 = [
    {
      key: "3",
      label: <Link to='/applicantsList'>Все соискатели</Link>,
    },
    {
      key: "4",
      label: <Link to='/applicantForm'>Создать объявление</Link>,
    },
  ];

  const onChange = (value) => {
    console.log("value", value);

    if (value === "vacancies") {
      navigate("/vacancyList");
    }
    if (value === "jobseakers") {
      navigate("/applicantsList");
    }
  };

  return (
    <div className='header'>
      <Link to='/' className='logo' style={{ textDecoration: "none" }}>
        {" "}
        <BuildOutlined style={{ fontSize: "24px", color: "#ffcd00" }} /> SIMSAR
      </Link>

      <div className='header_menu'>
        <Link to='/' className='header_menu_item'>
          БАШКЫ БЕТ
        </Link>

        <Dropdown menu={{ items }} placement='bottomLeft' arrow>
          <div className='font'>
            ВАКАНСИИ
            <DownOutlined />
          </div>
        </Dropdown>

        <Dropdown menu={{ items: items2 }} placement='bottomLeft' arrow>
          <div className='font'>
            СОИСКАТЕЛИ <DownOutlined />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default Header;
