import React from "react";
import "./JobList.css";
import JobItem from "../JobItem/JobItem";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ApplicantItem from "../ApplicantItem/ApplicantItem";

const JobList = () => {
  const jobList = useSelector((state) => state.job.jobList);
  const applicantsList = useSelector((state) => state.job.applicantsList);

  return (
    <div className='job_list'>
      <div className='job_list_block'>
        <h2 className='h2'>Жаңы кошулган жумуштардын тизмеси</h2>

        {jobList?.map(
          (it, index) => index <= 4 && <JobItem job={it} key={it.id} />
        )}

        <Link to='/vacancyList' className='all_btn'>
          Баардык жумуштар
        </Link>

        <h2 className='h2'>Жаңы кошулган ишчилердин тизмеси</h2>
        {applicantsList?.map(
          (it, index) =>
            index <= 4 && <ApplicantItem applicant={it} key={it.id} />
        )}
        <Link to='/applicantsList' className='all_btn'>
          Баардык ишчилер
        </Link>
      </div>
      <div className='joblist_add'>
        <div className='job_add_wrapper'>
          <div className='add_item'>
            <div className='add_img_block'>
              <img src='/assets/add1.svg' alt='' className='add_img' />
            </div>
          </div>
          <div className='add_item'>
            <div className='add_img_block'>
              <img src='/assets/add2.svg' alt='' className='add_img' />
            </div>
          </div>
          <div className='add_item'>
            <div className='add_img_block'>
              <img src='/assets/add1.svg' alt='' className='add_img' />
            </div>
          </div>

          <div className='add_item'>
            <div className='add_img_block'>
              <img src='/assets/add3.svg' alt='' className='add_img' />
            </div>
          </div>
          <div className='add_item'>
            <div className='add_img_block'>
              <img src='/assets/add4.svg' alt='' className='add_img' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobList;
