import React from "react";
import Header from "../../components/Header/Header";
import "./Newsdetails.css";
import JobItem from "../../components/JobItem/JobItem";
import Footer from "../../components/Footer/Footer";
import {
  ArrowLeftOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  HistoryOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import { useSelector } from "react-redux";
import WindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import { Link } from "react-router-dom";

const NewsDetails = () => {
  const vacancies = useSelector((state) => state.job.job);
  const job = vacancies?.vacancy_details;
  let windowDimensions = WindowDimensions();

  return (
    <div className='newsDetails'>
      {windowDimensions.width < 1100 ? <MobileHeader /> : <Header />}
      <Link to='/vacancyList' className='back'>
        <ArrowLeftOutlined className='icon' /> Назад
      </Link>
      <div className='news_details_wrapper'>
        <div className='news_details_content'>
          <div className='content_wrapper'>
            <div className='nd_title'>{job?.title}</div>
            <div className='vacancyDetails_header'>
              <div className='nd_price'>
                {" "}
                <DollarOutlined />
                {job?.salary}{" "}
              </div>
              {job?.deadline ? (
                <div className='job_deadline'>
                  <HistoryOutlined className='icon' />
                  Актуально до: {job?.deadline}
                </div>
              ) : null}
            </div>

            <div className='job_address'>
              {" "}
              <EnvironmentOutlined className='icon' />
              Дареги: {job?.address}
            </div>
            <div
              className='ns_desription'
              dangerouslySetInnerHTML={{ __html: job?.description }}
            ></div>
            <div className='ns_form'>
              <div className='post_detail_recall'>
                <PhoneOutlined />
                &nbsp;
                <Link to={`tel:+996${job?.phone}`}>0{job?.phone}</Link>
              </div>
            </div>
          </div>
          <div className='news_details_vertical_add'>
            <div className='job_add_wrapper'>
              <div className='add_item'>
                <div className='add_img_block'>
                  <img src='/assets/add1.svg' alt='' className='add_img' />
                </div>
              </div>
              <div className='add_item'>
                <div className='add_img_block'>
                  <img src='/assets/add2.svg' alt='' className='add_img' />
                </div>
              </div>
              <div className='add_item'>
                <div className='add_img_block'>
                  <img src='/assets/add1.svg' alt='' className='add_img' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='news_details_horizontal_add'>
          <img src='/assets/balance.svg' alt='' className='horizontal_img' />
        </div>

        <div className='bottom_block'>
          <div className='new_details_similar_jobs'>
            <h3>Окшош жумуштар</h3>
            {vacancies?.similar_vacancies?.map((it) => (
              <div>
                <JobItem job={it} />
              </div>
            ))}
          </div>
          <div className='news_details_vertical_add2'>
            <div className='add_item'>
              <div className='add_img_block'>
                <img src='/assets/add3.svg' alt='' className='add_img' />
              </div>
            </div>
            <div className='add_item'>
              <div className='add_img_block'>
                <img src='/assets/add4.svg' alt='' className='add_img' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsDetails;
